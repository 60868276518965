/**
 * Timetastic
 * Pricing page
 */
import React, { useEffect } from "react"
import { Link } from "gatsby"

// Helpers
import { animate, geoip2, trackCta } from "src/helpers"

// Components
import PricingQuotes from "src/components/pricingQuotes"
import Footer from "src/components/footer"
import FreeTrialAlt from "src/components/freeTrialAlt"
import Header from "src/components/header"
import Layout from "src/components/layout"
import SEO from "src/components/seo"
import PricingButton from "src/components/pricingButton"

// Styles
import "src/styles/templates/pricing.scss"
import "src/styles/animations/pricing.scss"

// SEO
const title = "Pricing for Timetastic. Timetastic costs."
const description =
  "Pricing for Timetastic - Timetastic pricing and cost information. 1 month free trial. Pay monthly by debit/credit card. No long term contracts."

// Year/Date for calendly.com link
const date = new Date()
const year = date.getFullYear()
const month = parseInt(date.getMonth(), 10) + 1

// JSX
const Default = () => {
  // Animate any elements with a class of 'is-unanimated'
  useEffect(() => {
    animate()
  }, [])

  return (
    <>
      <SEO
        title={title}
        useTitleTemplate={false}
        description={description}
        hrefLangs={{
          EnHref: "https://timetastic.co.uk/pricing/",
          UsaHref: "https://timetasticapp.com/pricing/",
          UkHref: "https://timetastic.co.uk/pricing/",
        }}
      />
      <Layout>
        <Header />
        <main className="c-pricing-main">
          <section className="c-pricing-hero is-unanimated u-text-centre">
            <div className="u-inner">
              <h1 className="h h2 c-pricing-hero__title">
                Try Timetastic{" "}
                <span className="u-bgcolor-gradient u-bgcolor-gradient-text">
                  for FREE
                </span>
              </h1>
              <p>
                Two super simple price plans, both with a 30 day free trial, no
                credit card required,
                <br />
                and you can switch between them anytime.
              </p>
            </div>
          </section>
          <section className="c-section c-pricing-panels-section">
            <div className="u-inner u-inner--l">
              <div className="c-pricing-panels">
                <div className="c-pricing-panel is-unanimated">
                  <div className="c-pricing-panel__body">
                    <h3 className="h h3">Timetastic</h3>
                    <p className="font-l">
                      The essentials to get you started with basic, paperless
                      planning.
                    </p>
                    <ul>
                      <li>Unlimited users and departments</li>
                      <li>Shared calendar and view of who’s off</li>
                      <li>PTO allowance record</li>
                      <li>Vacation approvals</li>
                      <li>Mobile and desktop app</li>
                    </ul>
                  </div>
                  <div className="u-text-center cta">
                    <p className="h h4">$1.50 per user/month</p>
                    <p>
                      <PricingButton
                        buttonCopy="Start using Timetastic for FREE"
                        trackCtaLabel="PricingHero"
                      />
                      <div className="c-free-trial__no-link">
                        100% free for one month, no credit card required.
                      </div>
                    </p>
                  </div>
                </div>
                <div className="c-pricing-panel c-pricing-panel__pro is-unanimated">
                  <div className="c-pricing-panel__body">
                    <h3 className="h h3 ok-hand">
                      Timetastic <span>Pro</span>
                    </h3>
                    <p className="font-l">
                      Everything you need to manage vacation and absence across
                      your business.
                    </p>
                    <ul>
                      <li>All the features of our basic plan plus...</li>
                      <li>Absence trends and insights</li>
                      <li>Alerts if team members are at risk of burnout</li>
                      <li>
                        Company-wide absence reports straight to your inbox
                      </li>
                      <li>Enhanced user profiles</li>
                      <li>
                        Integration with multiple platforms, including Teams
                      </li>
                      <li>Single sign-on for convenience and peace of mind</li>
                      <li>Accrued leave for accurate staff PTO allocation</li>
                      <li>Set clear boundaries with capped leave types</li>
                    </ul>
                  </div>
                  <div className="u-text-center cta">
                    <p className="h h4">$2.50 per user/month</p>
                    <p>
                      <PricingButton
                        buttonCopy="Start using Timetastic Pro for FREE"
                        startWithPro="true"
                        trackCtaLabel="PricingProHero"
                      />
                      <div className="c-free-trial__no-link">
                        100% free for one month, no credit card required.
                      </div>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="c-section is-unanimated">
            <div className="u-inner u-inner--m u-text-center">
              <h2 className="h h3">Really, from just $1.50 per user?</h2>
              <p>
                Yes you read correctly! We’ve made Timetastic one of those
                no-brainer decisions. That’s why you can try it, fully
                functional, no restrictions,{" "}
                <strong>completely FREE for 30 days</strong>. Plus, our support
                team is on hand to help guide you through setup. It really is
                the gold standard.
              </p>
            </div>
            <div className="u-inner u-inner--l">
              <PricingQuotes
                quotes={[
                  {
                    avatar: "/images/pricing/lee-bolam-extract.jpg",
                    company: "Extract Coffee",
                    name: "Lee Bolam",
                    quote:
                      "The daily email is a saviour, I don’t need to keep checking calendars to know what’s going on.",
                  },
                  {
                    avatar: "/images/pricing/chris-smith-custard.jpg",
                    company: "Custard",
                    name: "Chris Smith",
                    quote:
                      "Best thing about Timetastic, I’m now always up to date without any effort.",
                  },
                ]}
              />
            </div>
          </section>

          <section className="c-pricing-questions-section c-pricing-questions-section--wave">
            <div className="u-inner u-inner--800">
              <div className=" is-unanimated">
                <h2 className="c-pricing-questions__title h h2">
                  Pricing questions,{" "}
                  <span className="u-bgcolor-gradient u-bgcolor-gradient-text">
                    answered
                  </span>
                </h2>
              </div>
              <div className="c-pricing-questions">
                <div className="c-pricing-question is-unanimated">
                  <h3 className="c-pricing-question__title u-font-size-m u-font-bold">
                    How do I pay for Timetastic?
                  </h3>
                  <p>
                    Pay using any debit or credit card. Payments go through once
                    a month via Stripe and we’ll email you a VAT receipt.
                  </p>
                </div>
                <div className="c-pricing-question is-unanimated">
                  <h3 className="c-pricing-question__title u-font-size-m u-font-bold">
                    Is there a minimum term?
                  </h3>
                  <p>
                    Absolutely not, it’s just month to month. You can cancel
                    anytime, entirely self-service. No questions asked, no
                    penalties, no notice periods, 100% fair.
                  </p>
                </div>
                <div className="c-pricing-question is-unanimated">
                  <h3 className="c-pricing-question__title u-font-size-m u-font-bold">
                    What happens when the trial ends?
                  </h3>
                  <p>
                    If you want to continue with Timetastic beyond the trial
                    just enter your card details when you’re ready. If it’s not
                    for you — cancel anytime with a few clicks.
                  </p>
                </div>
                <div className="c-pricing-question is-unanimated">
                  <h3 className="c-pricing-question__title u-font-size-m u-font-bold">
                    Can you extend our trial?
                  </h3>
                  <p>
                    Of course, if you need more time to get to grips with
                    Timetastic then{" "}
                    <Link
                      to="/support/"
                      onClick={() => {
                        trackCta("PricingContactExtendTrial")
                      }}
                      className="u-em-link"
                    >
                      <span>send a message to our support team</span>
                    </Link>{" "}
                    and we’ll happily extend your trial.
                  </p>
                </div>
                <div className="c-pricing-question is-unanimated">
                  <h3 className="c-pricing-question__title u-font-size-m u-font-bold">
                    Where can I read some reviews?
                  </h3>
                  <p>
                    There are thousands of reviews{" "}
                    <a
                      href="https://www.reviews.co.uk/company-reviews/store/timetastic"
                      rel="nofollow noreferrer noopener"
                      target="_blank"
                      className="u-em-link"
                    >
                      <span>here at Reviews.io</span>
                    </a>
                    . We’re rated 4.7/5 on Capterra from over{" "}
                    <a
                      href="https://www.capterra.com/reviews/146655/Timetastic?utm_source=vendor&utm_medium=badge&utm_campaign=capterra_reviews_badge"
                      rel="nofollow noreferrer noopener"
                      target="_blank"
                      className="u-em-link"
                    >
                      <span>500+ reviews</span>
                    </a>
                    , and we’re rated 4.8/5 on the{" "}
                    <a
                      href="https://apps.apple.com/gb/app/timetastic/id633985417"
                      rel="nofollow noreferrer noopener"
                      target="_blank"
                      className="u-em-link"
                    >
                      <span>Apple App Store</span>
                    </a>
                    .
                  </p>
                </div>
                <div className="c-pricing-question is-unanimated">
                  <h3 className="c-pricing-question__title u-font-size-m u-font-bold">
                    What if I have more questions?
                  </h3>
                  <p>
                    No problem,{" "}
                    <Link
                      className="u-em-link"
                      to="/support/"
                      onClick={() => {
                        trackCta("PricingContactMoreQuestions")
                      }}
                    >
                      <span>get in touch</span>
                    </Link>{" "}
                    and we’ll answer them.
                  </p>
                </div>
              </div>
              <div className="c-pricing-questions__media">
                <a
                  href="https://www.capterra.com/reviews/146655/Timetastic?utm_source=vendor&utm_medium=badge&utm_campaign=capterra_reviews_badge"
                  rel="nofollow noreferrer noopener"
                  target="_blank"
                  className="capterra"
                >
                  {" "}
                  <img
                    border="0"
                    src="https://assets.capterra.com/badge/3fb93b4fa79a460bf121a4febd992c0c.png?v=2103571&p=146655"
                    loading="lazy"
                  />
                </a>
                <a
                  href="https://www.reviews.co.uk/company-reviews/store/timetastic"
                  rel="nofollow noreferrer noopener"
                  target="_blank"
                  className="reviews-io"
                >
                  <img
                    src="https://media.reviews.co.uk/badge/timetastic.png"
                    loading="lazy"
                  />
                </a>
                <a
                  href="https://uk.trustpilot.com/review/timetastic.co.uk"
                  rel="nofollow noreferrer noopener"
                  target="_blank"
                  className="reviews-io"
                >
                  <img
                    src="/images/features/social-proof/trustpilot-badge.png"
                    loading="lazy"
                  />
                </a>
              </div>
            </div>
          </section>
        </main>
        <Footer />
      </Layout>
    </>
  )
}
export default Default
